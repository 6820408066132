// import React, { useCallback, useMemo } from "react";
// import { useTranslation } from "react-i18next";
// import { css } from "goober";
// import { useSnapshot } from "@/util/use-snapshot.mjs";

// import { readState } from "@/__main__/app-state.mjs";
// import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
// import { GAME_SHORT_NAMES, GAME_SYMBOL_CS2 } from "@/app/constants.mjs";
// import {
//   DEFAULT_GAME_MODE_KEY,
//   GAME_MODE_TYPES,
//   GAME_MODES,
//   TEAM_IDS,
// } from "@/game-csgo/constants.mjs";
// import {
//   createLastNChartData,
//   createMatchChartData,
// } from "@/game-csgo/create-chart-data.mjs";
// import { csgoBenchmarkRefs, OVERLAY_IDS } from "@/game-csgo/refs.mjs";
// import { getGameModeByMatch, getMatchResultLabel } from "@/game-csgo/utils.mjs";
// import CSGOAvatar from "@/inline-assets/csgo-avatar.svg";
// import CSGOCounterTerroristIcon from "@/inline-assets/csgo-counter-terrorist-icon.webp";
// import CSGOTerroristIcon from "@/inline-assets/csgo-terrorist-icon.webp";
// import SharedBenchmarkOverlay from "@/shared/BenchmarkOverlay.jsx";
// import { getLocaleString } from "@/util/i18n-helper.mjs";
// import partition from "@/util/partition.mjs";
// import { useRoute } from "@/util/router-hooks.mjs";

// const useCsgoLastN = () => ({});

// export const initialPosition = css`
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   margin-bottom: 15vh;
//   margin-right: 5vw;
// `;

// // Todo: This was disabled on the CS2 launch, this heavily needs to be refactored or completely redone
// // DO NOT ENABLE BENCHMARKING OVERLAY WITHOUT REFACTORING
// function BenchmarkOverlay() {
//   const { t } = useTranslation();
//   const route = useRoute();
//   const profileId = route.searchParams.get("profileId");
//   const matchId = route.searchParams.get("matchId");

//   const {
//     csgo: { matches, profiles, lastGameMode, liveGame },
//     settings,
//   } = useSnapshot(readState);

//   const match = matches?.[matchId] ?? null;
//   const gameMode = useMemo(
//     () =>
//       getGameModeByMatch(match) ||
//       lastGameMode ||
//       GAME_MODES[DEFAULT_GAME_MODE_KEY],
//     [match, lastGameMode],
//   );
//   const playerMatchStats = match?.playerMatchStats;
//   const isBenchmarkOverlayEnabled =
//     settings.cs2.overlays?.isBenchmarkOverlayEnabled;

//   const rankStats = useMemo(() => {
//     return []; // TODO: waiting on endpoint
//   }, []);

//   // current player, and then everyone else on their team
//   const [[currentPlayer], team] = useMemo(() => {
//     if (!playerMatchStats) return [[], []];

//     // const team = getScoreCardTeams(
//     //   Object.values(playerMatchStats),
//     //   currentPlayerStats,
//     //   [],
//     //   gameMode?.scoringMethod,
//     // )[0];

//     // separate the current player from the team
//     return team
//       ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         partition(team, (player: any) => player.steamId === profileId)
//       : [[], []];
//   }, [playerMatchStats, profileId]);

//   const lastNStats = useCsgoLastN();

//   const score = useMemo(() => {
//     const tab = {
//       id: "score",
//       text: t("common:stats.score", "Score"),
//       value: matchId ? currentPlayer?.score : undefined,
//       valueStr: matchId
//         ? getLocaleString(currentPlayer?.score, {
//             maximumFractionDigits: 0,
//           })
//         : "-",
//       avgValue: lastNStats?.score,
//       avgValueStr: getLocaleString(lastNStats?.score, {
//         maximumFractionDigits: 0,
//       }),
//     };

//     const format = (value) =>
//       getLocaleString(value, {
//         maximumFractionDigits: 0,
//       });

//     const chartData = matchId
//       ? createMatchChartData(
//           currentPlayer,
//           "score",
//           profiles,
//           rankStats,
//           team,
//           format,
//         )
//       : createLastNChartData(
//           profileId,
//           "score",
//           profiles,
//           rankStats,
//           lastNStats,
//           format,
//           t,
//         );

//     return { tab, chartData };
//   }, [
//     profileId,
//     matchId,
//     currentPlayer,
//     profiles,
//     rankStats,
//     lastNStats,
//     team,
//     t,
//   ]);

//   const damage = useMemo(() => {
//     if (gameMode?.deathmatch) return {};

//     const key = gameMode?.rounds ? "adr" : "damage";

//     const tab = {
//       id: "damage",
//       text: t("common:stats.adr", "ADR"),
//       value: matchId ? currentPlayer?.[key] : undefined,
//       valueStr: matchId
//         ? getLocaleString(currentPlayer?.[key], {
//             maximumFractionDigits: 0,
//           })
//         : "-",
//       avgValue: lastNStats?.adr,
//       avgValueStr: getLocaleString(lastNStats?.adr, {
//         maximumFractionDigits: 0,
//       }),
//     };

//     const format = (value) =>
//       getLocaleString(value, {
//         maximumFractionDigits: 0,
//       });

//     const chartData = matchId
//       ? createMatchChartData(
//           currentPlayer,
//           key,
//           profiles,
//           rankStats,
//           team,
//           format,
//         )
//       : createLastNChartData(
//           profileId,
//           key,
//           profiles,
//           rankStats,
//           lastNStats,
//           format,
//           t,
//         );

//     return { tab, chartData };
//   }, [
//     profileId,
//     matchId,
//     gameMode,
//     currentPlayer,
//     profiles,
//     rankStats,
//     lastNStats,
//     team,
//     t,
//   ]);

//   const kda = useMemo(() => {
//     const tab = {
//       id: "kda",
//       text: t("common:stats.kda", "KDA"),
//       value: matchId ? currentPlayer?.kda : undefined,
//       valueStr: matchId
//         ? getLocaleString(currentPlayer?.kda, {
//             maximumFractionDigits: 1,
//           })
//         : "-",
//       avgValue: lastNStats?.kda || 0,
//       avgValueStr: getLocaleString(lastNStats?.kda || 0, {
//         maximumFractionDigits: 1,
//       }),
//     };

//     const format = (value) =>
//       getLocaleString(value, { maximumFractionDigits: 1 });

//     const chartData = matchId
//       ? createMatchChartData(
//           currentPlayer,
//           "kda",
//           profiles,
//           rankStats,
//           team,
//           format,
//         )
//       : createLastNChartData(
//           profileId,
//           "kda",
//           profiles,
//           rankStats,
//           lastNStats,
//           format,
//           t,
//         );

//     return { tab, chartData };
//   }, [
//     profileId,
//     matchId,
//     currentPlayer,
//     profiles,
//     rankStats,
//     lastNStats,
//     team,
//     t,
//   ]);

//   const tabs = useMemo(() => {
//     switch (gameMode?.type) {
//       case GAME_MODE_TYPES.CASUAL:
//       case GAME_MODE_TYPES.COMPETITIVE:
//         return [score.tab, damage.tab];
//       case GAME_MODE_TYPES.DEATHMATCH:
//         return [score.tab, kda.tab];
//       default:
//         return [score.tab];
//     }
//   }, [gameMode?.type, score, damage, kda]);

//   const getChartData = useCallback(
//     (type) => {
//       switch (type) {
//         case score.tab?.id:
//           return score.chartData;
//         case damage.tab?.id:
//           return damage.chartData;
//         case kda.tab?.id:
//           return kda.chartData;
//         default:
//           return [];
//       }
//     },
//     [score, damage, kda],
//   );

//   const placement = 0;
//   const title = getMatchResultLabel(placement, match, t).label;

//   const queueTitle = useMemo(() => {
//     if (matchId) {
//       return [gameMode?.t, gameMode?.label];
//     }

//     const wins = lastNStats?.wins || 0;
//     const losses = lastNStats?.losses || 0;
//     const total = wins + losses;

//     return [
//       "common:modeWinsLossesRate",
//       "{{wins, number}}W {{losses, number}}L {{winRate, percent}}",
//       {
//         wins: wins,
//         losses: losses,
//         winRate: total ? wins / total : 0,
//       },
//     ];
//   }, [matchId, gameMode, lastNStats.wins, lastNStats.losses]);

//   const openApp = useCallback(() => {
//     blitzMessage(EVENTS.CSGO_VIEW_BLITZ_APP, {
//       matchId,
//       profileId,
//     });
//   }, [matchId, profileId]);

//   const avatarIcon = useMemo(() => {
//     if (gameMode?.scoringMethod === "score") {
//       return <CSGOAvatar width="100%" height="100%" />;
//     }

//     switch (currentPlayer?.teamId) {
//       case TEAM_IDS.T:
//         return <img width="100%" height="100%" src={CSGOTerroristIcon} />;
//       case TEAM_IDS.CT:
//         return (
//           <img width="100%" height="100%" src={CSGOCounterTerroristIcon} />
//         );
//       default:
//         return <CSGOAvatar width="100%" height="100%" />;
//     }
//   }, [currentPlayer, gameMode]);

//   if (
//     liveGame ||
//     (matchId && !currentPlayer) ||
//     (!matchId && !gameMode) ||
//     !isBenchmarkOverlayEnabled
//   ) {
//     return null;
//   }

//   return (
//     <SharedBenchmarkOverlay
//       game={GAME_SHORT_NAMES[GAME_SYMBOL_CS2]}
//       overlayId={OVERLAY_IDS.csgoBenchmark}
//       overlayIdAd={OVERLAY_IDS.csgoBenchmarkAd}
//       matchId={matchId}
//       icon={avatarIcon}
//       title={title}
//       titleColor={matchId ? "var(--turq)" : undefined}
//       stats={{ recentMatchesCount: lastNStats.matches }}
//       queue={queueTitle}
//       tabs={tabs}
//       getChartData={getChartData}
//       openApp={openApp}
//       refs={csgoBenchmarkRefs}
//       subtitle={undefined}
//       name={undefined}
//       refetchEvent={undefined}
//       toggleSettings={undefined}
//       initialPosition={initialPosition}
//       showAd={undefined}
//     />
//   );
// }

// export function meta() {
//   return {
//     title: [null, "CS2 - Benchmark Overlay"],
//     description: [null, "CS2 Benchmark Overlay"],
//   };
// }

// export default BenchmarkOverlay;
